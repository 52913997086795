import React from "react"
import { IoLogoFacebook, IoLogoInstagram } from "react-icons/io"
import {Link} from 'gatsby'
import logo from '../images/logo.svg'


const footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-6">
            <h2 className="footer__logo">
            <Link to="/" className="navbar-brand nav_logo" href="/">
              <img src={logo} style={{ height: '8rem' }} />
            </Link>
            </h2>
            <p className="footer__description">
            We are a new-age web development agency offering dynamic and diverse digital solutions under one roof. We offer services in web development, software development, app development, web design, graphic design, logo design, and search engine optimization (SEO). 
            </p>
            {/* <div className="d-flex mb-small">
              <IoLogoFacebook className="footer__icon" />
              <IoLogoInstagram className="footer__icon" />
            </div> */}
            <p className="footer__copyright mb-3 mb-md-0">
              &copy; Contrion Technology Private Limited 2019-21.All rights reserved.
            </p>
          </div>
          <div className=" col-12 col-sm-12 col-md-6">
            <div className="row ml-0 ml-md-5">
              <div className="col-4">
                <h4>Company</h4>
                <ul className="footer__link  ">
                   
                  <li className="footer__link-item">

                  <Link to="/"  >

                    Home
                    </Link>

                  </li>
                  <li className="footer__link-item">
                  <Link to="/aboutus"  >

About Us
</Link>
                  </li>
                  <li className="footer__link-item">
                  <Link to="/services"  >

Services
</Link>
                  </li>
            
                  <li className="footer__link-item">
                  <Link to="/contactus"  >

Contact
</Link>
                  </li>
                </ul>
              </div>
              {/* <div className="col-4">
                <h4>Usefull Links</h4>
                <ul className="footer__link">
                  <li className="footer__link-item">Client area</li>
                  <li className="footer__link-item">Support</li>
                  <li className="footer__link-item">FAQ's</li>
                  <li className="footer__link-item">Newsletter</li>
                  <li className="footer__link-item">Our Sitemap</li>
                </ul>
              </div> */}
              <div className="col-4">
                <h4>Services</h4>
                <ul className="footer__link">
                <Link to="/services"  >
                  <li className="footer__link-item">
                 



                  Web Development
                  </li>
                  </Link>
                  <Link to="/services"  >
                  <li className="footer__link-item">
                 



                  Software development
                  </li>
                  </Link>

                  <Link to="/services"  >
                  <li className="footer__link-item">
                 



                  Web Design
                  </li>
                  </Link>
                  <Link to="/services"  >
                  <li className="footer__link-item">
                 



                  Graphic Design
                  </li>
                  </Link>
                  <Link to="/services"  >
                  <li className="footer__link-item">
                 



                  Search Engine Optimization(SEO)
                  </li>
                  </Link>
                  <Link to="/services"  >
                  <li className="footer__link-item">
                 



                  Digital Marketing
                  </li>
                  </Link>

                  
                  
                 
                  {/* <li className="footer__link-item">Blogs</li>
                  <li className="footer__link-item">Contact</li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default footer
