// import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { useSpring, animated } from "react-spring"

import logo from '../images/logo.svg'


const Header = props => {
  const [visible, setVisible] = useState(false)
  const [link, setLink] = useState(null)

  console.log("Header PROPSSS", props)

  const borderProps = useSpring({
    to: { color: "rgb(228, 24, 75)", "font-weight": "black" },
  })

  const [listProps, set, stop] = useSpring(() => ({
    height: "0%",
  }))

  set({ height: visible ? "100%" : "0%" })

  stop()

  return (
    <header>
      <div className="navbar p-0">
        <div className="container header-container">
          <div className="main_nav">
            <Link className="navbar-brand nav_logo" to="/">
              <img src={logo} style={{ height: '8rem' }} />
            </Link>

            <div
              onClick={() => setVisible(!visible)}
              className="dropdown-box d-lg-none "
            >
              <div
                className={`dropdown_icon ${visible ? "dropdown-animate" : ""}`}
              ></div>
            </div>

            {/* <div class="hamburger hamburger--emphatic-r">
            <div class="hamburger-box">
              <div class="hamburger-inner"></div>
            </div>
          </div> */}

            <ul className="nav_list">
              <Link to="/" activeClassName="active">
                <li>Home</li>
              </Link>

              <Link to="/services" activeClassName="active">
                <li>Services</li>
              </Link>

              {/* <Link to="/portfolio" activeClassName="active">
                <li>Portfolio</li>
              </Link> */}

              <Link to="/aboutus" activeClassName="active">
                <li>About us</li>
              </Link>

              {/* <li>Blog</li> */}

              <Link to="/contactus" activeClassName="active">
                <li>Contact</li>
              </Link>
            </ul>
          </div>
        </div>
      </div>

      {visible && (
        <animated.div style={listProps} className="dropdown_menu d-lg-none">
          <ul>
            <Link to="/" onClick={() => setLink("/")}>
              <li>Home</li>
            </Link>
            <Link to="/services" onClick={() => setLink("/services")}>
              <li>Services</li>
            </Link>

            <Link to="/portfolio" onClick={() => setLink("/portfolio")}>
              <li>Portfolio</li>
            </Link>
            {/* 
            <Link to="/blog">
              <li>Blog</li>
            </Link> */}

            <Link to="/aboutus" onClick={() => setLink("/aboutus")}>
              <li>About us</li>
            </Link>
            <Link to="/contactus" onClick={() => setLink("/contactus")}>
              <li>Contact</li>
            </Link>
          </ul>
        </animated.div>
      )}
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
